import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { db } from '..';
import { historyTagsFilterReceived, moodsReceived, recourcesReceived } from '../appSlice';

import bottomBarBg from "../../assets/selector_background.9.png";
import bottomBarDot from "../../assets/selector_dot.png";
import bottomBarSelector from "../../assets/selector.png";

export const GET_MOODS = "GET_MOODS";
export const getMoodsAction = () => ({ type: GET_MOODS });

export const MOOD_TAG_SELECTED = "MOOD_TAG_SELECTED";
export const moodTagSelectedAction = (payload) => ({ type: MOOD_TAG_SELECTED, payload });

export const RESOURCES = {};

export function fetchResources() {
    return new Promise((resolve, reject) => {
        let barParts = [{ id: "bg", img: bottomBarSelector }, { id: "dot", img: bottomBarDot }, { id: "selector", img: bottomBarSelector }];
        let count = barParts.length;

        const onLoadFinish = (imgData) => (file) => {
            if (--count > 0) return;

            const resources = {};
            barParts.forEach((bp) => resources[bp.id] = { image: bp.file });
            Object.assign(RESOURCES, resources);
            resolve(resources);
        }

        barParts.forEach((l) => {
            let img = new Image();
            img.src = l.img;
            l.file = img;
            img.onload = onLoadFinish(l);
        });
    });
}

function* fetchMoods(action) {
    const data = [...db].sort((a, b) => a.created - b.created);
    yield put(moodsReceived(data));
}

function* moodTagSelected(action) {
    yield put(historyTagsFilterReceived(action.payload?.tag));
}

function* appSaga() {
    yield all([
        takeLatest(GET_MOODS, fetchMoods),
        takeLatest(MOOD_TAG_SELECTED, moodTagSelected),
    ]);
}

export default appSaga;