import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Box, Card, Tab, Tabs } from "@mui/material";

import { calcGraph } from "../../utils/graph";
import { hexToRGBA } from "../../utils/draw";
import { a11yProps } from "../../utils/muiHelper";
import { drawBG, drawSummaryUI, getDotsPath, getMainTrendPath, getSecondaryTrendPath, Trend } from "./helpers";
import { CHART_DETAIL_LEVEL } from "../../data";

import style from "./moodSummary.module.scss";

const TAB_ID = {
    SUMMARY: 0,
}

const MoodSummaryStatic = () => {
    const [trends, setTrends] = React.useState(null);
    const [tabIndex, setTabIndex] = React.useState(0);

    const moods = useSelector((state) => state.app.moods);

    const canvasRef = useRef({});

    useEffect(() => {
        if (!moods.length) return;

        let trendsData = {};
        //summary
        let startDate = moods[0].created - 1;
        let lastDate = moods[moods.length - 1].created + 1;
        trendsData[TAB_ID.SUMMARY] = new Trend(startDate, lastDate - startDate + 2, Math.floor(CHART_DETAIL_LEVEL * 1.5 + 0.5), false);
        trendsData[TAB_ID.SUMMARY].drawUI = drawSummaryUI;

        setTrends(trendsData);
    }, [moods]);

    useEffect(() => {
        if (!trends) return;

        let ldb = moods;
        let trend = trends[tabIndex];

        let { startDate, period, detailLevel, cyclic } = trend;
        let { aSamples, aDispSamples, aX, aY, step, rank } = calcGraph(ldb, startDate, period, detailLevel, cyclic);

        let xCoef = canvasRef.current.width / detailLevel;
        let yCoef = canvasRef.current.height;

        let ctx = canvasRef.current.getContext("2d");

        drawBG(canvasRef.current, ctx);

        let backPath = getSecondaryTrendPath(canvasRef.current, aSamples, aDispSamples, xCoef, yCoef, step);
        let trendPath = getMainTrendPath(aSamples, xCoef, yCoef, step);
        let dotsPath = getDotsPath(aX, aY, xCoef, yCoef);

        ctx.lineWidth = '0.3';
        ctx.strokeStyle = 'white';
        ctx.fillStyle = hexToRGBA('#000000', 0.1);

        ctx.fill(backPath);
        ctx.stroke(backPath);

        ctx.lineWidth = '1.2';
        ctx.strokeStyle = 'white';
        ctx.stroke(trendPath);

        ctx.fillStyle = "white";
        ctx.fill(dotsPath);

        ctx.font = "normal 16px sans-serif";
        ctx.fillStyle = hexToRGBA('#FFFFFF', 0.4);

        trend.drawUI(canvasRef.current, ctx);
    }, [canvasRef, tabIndex, trends, moods]);

    const handleChange = (_, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <Card sx={{ width: '480px' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabIndex} onChange={handleChange} aria-label="summary tabs">
                    <Tab label="Summary" {...a11yProps('mood-summary-static', TAB_ID.SUMMARY)} />
                </Tabs>
            </Box>
            <div className={style['graph-wrapper']}>
                <canvas ref={canvasRef} width={480} height={360} />
            </div>
        </Card>
    );
}

export { MoodSummaryStatic };