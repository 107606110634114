import { Box, Card, List, ListItem, ListItemButton, ListItemText, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";

import { db_tags, MOOD_COLORS } from "./../../data";
import { format } from "date-fns";
import { hexToRGBA } from "../../utils/draw";
import { a11yProps, TabPanel } from "../../utils/muiHelper";
import { useDispatch } from "react-redux";
import { moodTagSelectedAction } from "../../data/sagas/appSaga";

const getMoodColor = (average) => {
    let floor = Math.floor(average);
    let ceil = Math.ceil(average);
    let floorColor = parseInt(MOOD_COLORS[floor].color.replace(/^#/, ''), 16);
    let ceilColor = parseInt(MOOD_COLORS[ceil].color.replace(/^#/, ''), 16);
    let p = average - floor;

    let r = (((floorColor >> 24) & 0xFF) * (1 - p)) + (((ceilColor >> 24) & 0xFF) * (p));
    let g = (((floorColor >> 16) & 0xFF) * (1 - p)) + (((ceilColor >> 16) & 0xFF) * (p));
    let b = (((floorColor >> 8) & 0xFF) * (1 - p)) + (((ceilColor >> 8) & 0xFF) * (p));

    let color = (r << 16) | (g << 8) | (b << 0);//(0xFF << 24) |

    return '#' + color.toString(16);
}

const MoodTagsSummary = () => {
    const [value, setValue] = React.useState(0);
    const [positiveTags, setPositiveTags] = React.useState([]);
    const [negativeTags, setNegativeTags] = React.useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        const tags = db_tags.filter((t) => t.count);

        let resP = [];
        let resN = [];
        //let positive = true;
        tags.forEach((t) => {
            let average = (t.sumMoodLevel / t.count).toFixed(1);
            if (average < 5) {
                resP.push({
                    tag: t.tag,
                    tagAverage: 10 - average,
                    count: t.count,
                    moodSum: t.sumMoodLevel,
                    color: getMoodColor(average),
                });
            } else if (average >= 5) {
                resN.push({
                    tag: t.tag,
                    tagAverage: 10 - average,
                    count: t.count,
                    moodSum: t.sumMoodLevel,
                    color: getMoodColor(average),
                });
            }
        });

        setPositiveTags(resP.sort((a, b) => b.tagAverage - a.tagAverage));
        setNegativeTags(resN.sort((a, b) => a.tagAverage - b.tagAverage));
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleTagClick = (tag) => {
        dispatch(moodTagSelectedAction(tag));
    }

    return (
        <Card sx={{ width: 480 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Positive" {...a11yProps(0)} />
                    <Tab label="Negative" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} sx={{ overflow: 'auto', maxHeight: 420 }}>
                <List>
                    {positiveTags.map((tag) => (
                        <ListItem key={tag.tag} disablePadding sx={{ backgroundColor: tag.color, color: 'aliceblue' }} onClick={() => handleTagClick(tag)}>
                            <ListItemButton>
                                <ListItemText primary={
                                    <>
                                        <Typography
                                            sx={{ display: 'inline', marginRight: 2, fontWeight: 600 }}
                                            component="span"
                                        //variant="body2"
                                        //color="white"
                                        >
                                            {`#${tag.tag}`}
                                        </Typography>
                                        {tag.tagAverage.toFixed(1)}
                                    </>
                                } />
                            </ListItemButton>
                        </ListItem>)
                    )}
                </List>
            </TabPanel>
            <TabPanel value={value} index={1} sx={{ overflow: 'auto', maxHeight: 420 }}>
                <List>
                    {negativeTags.map((tag) => (
                        <ListItem key={tag.tag} disablePadding sx={{ backgroundColor: tag.color, color: 'white' }} onClick={() => handleTagClick(tag)}>
                            <ListItemButton>
                                <ListItemText primary={
                                    <>
                                        <Typography
                                            sx={{ display: 'inline', marginRight: 2, fontWeight: 600 }}
                                            component="span"
                                        //variant="body2"
                                        //color="white"
                                        >
                                            {`#${tag.tag}`}
                                        </Typography>
                                        {tag.tagAverage.toFixed(1)}
                                    </>
                                } />
                            </ListItemButton>
                        </ListItem>)
                    )}
                </List>
            </TabPanel>
        </Card>
    );
}

export { MoodTagsSummary };