import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Box, Card, IconButton, Tab, Tabs } from "@mui/material";
import { setDay } from "date-fns";

import { calcGraph } from "../../utils/graph";
import { hexToRGBA } from "../../utils/draw";
import { a11yProps } from "../../utils/muiHelper";
import { drawBG, drawDayUI, drawSummaryUI, drawWeekUI, getDotsPath, getMainTrendPath, getSecondaryTrendPath, Trend } from "./helpers";
import { MoodSummaryFilter } from "../moodSummaryFilter";
import { CHART_DETAIL_LEVEL } from "../../data";

import style from "./moodSummary.module.scss";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";

const TAB_ID = {
    SUMMARY: 0,
    WEEK: 1,
    DAY: 2,
}

const MoodSummary = () => {
    const [trends, setTrends] = React.useState(null);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [filters, setFilters] = React.useState([]);//{id, filter}
    const [dateRange, setDateRange] = React.useState({});
    const [pagination, setPagination] = React.useState({});

    const moods = useSelector((state) => state.app.moods);

    const canvasRef = useRef({});

    useEffect(() => {
        if (!moods.length) return;

        let trendsData = {};
        //summary
        let startDate = moods[0].created - 1;
        let lastDate = moods[moods.length - 1].created + 1;
        trendsData[TAB_ID.SUMMARY] = new Trend(startDate, lastDate - startDate + 2, Math.floor(CHART_DETAIL_LEVEL * 1.5 + 0.5), false);
        trendsData[TAB_ID.SUMMARY].drawUI = drawSummaryUI;
        //week
        startDate = new Date();
        startDate = setDay(startDate, 0);
        startDate.setUTCHours(0, 0, 0, 0);
        trendsData[TAB_ID.WEEK] = new Trend(startDate.getTime(), 1000 * 60 * 60 * 24 * 7, CHART_DETAIL_LEVEL, true);
        trendsData[TAB_ID.WEEK].drawUI = drawWeekUI;
        //day
        startDate = new Date();
        startDate.setUTCHours(6, 0, 0, 0);
        trendsData[TAB_ID.DAY] = new Trend(startDate.getTime(), 1000 * 60 * 60 * 24, CHART_DETAIL_LEVEL, true);
        trendsData[TAB_ID.DAY].drawUI = drawDayUI;

        setTrends(trendsData);
        setDateRange({ min: moods[0].created, max: moods[moods.length - 1].created });
        setPagination({
            dateFrom: moods[0].created,
            dateTo: moods[moods.length - 1].created,
            canBack: false,
            canForward: false,
        });
        console.log('INIT TRENDS', trendsData);
    }, [moods]);

    useEffect(() => {
        if (!trends) return;

        console.log('REDRAW');
        let ldb = moods;
        let trend = trends[tabIndex];

        //apply filters
        if (filters.length > 0) {
            let filteredData = ldb.filter(filters[0].exec());

            if (filteredData.length === 0) {
                console.warn("No records after filter apply");
            } else {
                ldb = filteredData;
                console.log('Filtered: ', ldb.length);

                if (tabIndex === TAB_ID.SUMMARY) {
                    let startDate = ldb[0].created - 1;
                    let lastDate = ldb[ldb.length - 1].created + 1;
                    trends[tabIndex].startDate = startDate;
                    trends[tabIndex].period = lastDate - startDate + 2;
                    trends[tabIndex].detailLevel = Math.min(Math.floor(CHART_DETAIL_LEVEL * 1.5 + 0.5), ldb.length);
                } else {
                    trends[tabIndex].detailLevel = Math.min(CHART_DETAIL_LEVEL, ldb.length);
                }
            }
        }

        let { startDate, period, detailLevel, cyclic } = trend;
        let { aSamples, aDispSamples, aX, aY, step, rank } = calcGraph(ldb, startDate, period, detailLevel, cyclic);
        console.log('Rank=', rank);

        let xCoef = canvasRef.current.width / detailLevel;
        let yCoef = canvasRef.current.height;

        let ctx = canvasRef.current.getContext("2d");

        drawBG(canvasRef.current, ctx);

        let backPath = getSecondaryTrendPath(canvasRef.current, aSamples, aDispSamples, xCoef, yCoef, step);
        let trendPath = getMainTrendPath(aSamples, xCoef, yCoef, step);
        let dotsPath = getDotsPath(aX, aY, xCoef, yCoef);

        ctx.lineWidth = '0.3';
        ctx.strokeStyle = 'white';
        ctx.fillStyle = hexToRGBA('#000000', 0.1);

        ctx.fill(backPath);
        ctx.stroke(backPath);

        ctx.lineWidth = '1.2';
        ctx.strokeStyle = 'white';
        ctx.stroke(trendPath);

        ctx.fillStyle = "white";
        ctx.fill(dotsPath);

        ctx.font = "normal 16px sans-serif";
        ctx.fillStyle = hexToRGBA('#FFFFFF', 0.4);

        trend.drawUI(canvasRef.current, ctx);
    }, [canvasRef, tabIndex, trends, filters, moods]);

    const handleChange = (_, newValue) => {
        setTabIndex(newValue);
    };

    const onFilterSubmit = data => {
        let { dateFrom, dateTo } = data;
        let dateFromUTC = new Date(dateFrom).getTime();
        let dateToUTC = new Date(dateTo).getTime();

        setFilters([{
            filter: {
                dateFrom: dateFromUTC,
                dateTo: dateToUTC,
            },
            exec: function () { return (d) => d.created >= this.filter.dateFrom && d.created <= this.filter.dateTo },
        }]);

        setPagination({
            dateFrom: dateFromUTC,
            dateTo: dateToUTC,
            period: dateToUTC - dateFromUTC,
            canBack: dateFromUTC - dateRange.min > 0,
            canForward: dateRange.max - dateToUTC > 0,
        });
    }

    const scrollBack = () => {
        const { dateFrom, period } = pagination;

        let newDateFrom = Math.max(dateFrom - period, dateRange.min);
        let newDateTo = newDateFrom + period;

        setPagination({
            ...pagination,
            dateFrom: newDateFrom,
            dateTo: newDateTo,
            canBack: newDateFrom - dateRange.min > 0,
            canForward: dateRange.max - newDateTo > 0,
        });

        const newFilters = [...filters];
        const dateFilter = newFilters[0];
        dateFilter.filter.dateFrom = newDateFrom;
        dateFilter.filter.dateTo = newDateTo;

        setFilters(newFilters);
    }

    const scrollForward = () => {
        const { dateTo, period } = pagination;

        let newDateTo = Math.min(dateTo + period, dateRange.max);
        let newDateFrom = newDateTo - period;

        setPagination({
            ...pagination,
            dateFrom: newDateFrom,
            dateTo: newDateTo,
            canBack: newDateFrom - dateRange.min > 0,
            canForward: dateRange.max - newDateTo > 0,
        });

        const newFilters = [...filters];
        const dateFilter = newFilters[0];
        dateFilter.filter.dateFrom = newDateFrom;
        dateFilter.filter.dateTo = newDateTo;

        setFilters(newFilters);
    }

    return (
        <Card sx={{ width: '480px' }}>
            <MoodSummaryFilter
                dateRange={dateRange}
                onFilterSubmit={onFilterSubmit}
            />
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabIndex} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Summary" {...a11yProps('mood-summary', TAB_ID.SUMMARY)} />
                    {/* <Tab label="By Week" {...a11yProps('mood-summary', TAB_ID.WEEK)} />
                    <Tab label="By Day" {...a11yProps('mood-summary', TAB_ID.DAY)} /> */}
                </Tabs>
            </Box>
            <div className={style['graph-wrapper']}>
                <canvas ref={canvasRef} width={480} height={360} />
                {tabIndex === TAB_ID.SUMMARY &&
                    <div className={style['graph-controlls']}>
                        <IconButton color="standart" aria-label="back" onClick={scrollBack} disabled={!pagination.canBack}>
                            <ArrowBackIosNew />
                        </IconButton>
                        <IconButton color="standart" aria-label="forward" onClick={scrollForward} disabled={!pagination.canForward}>
                            <ArrowForwardIos />
                        </IconButton>
                    </div>
                }
            </div>
        </Card>
    );
}

export { MoodSummary };