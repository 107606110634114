import { format } from "date-fns";

import { MOOD_COLORS } from "../../../data";
import { hexToRGBA } from "../../../utils/draw";

export const barWidth = 24;
export const barSpace = 1;
export const maxMoodLevel = 10;
export const topBarPadding = 50;
export const bottomBarPadding = 22 + 10;

export const getGroups = (moods, onRectSelect, canvas) => {
    const maxBarHeight = canvas.height - topBarPadding - bottomBarPadding;
    const selectorHeight = 7;

    const groups = [];
    const elements = [];
    const prevGroupPos = {};

    const keys = Object.keys(moods);
    keys.sort((a, b) => a - b);

    keys.forEach((k) => {
        const moodsList = moods[k];

        const date = new Date(Number(k));
        var text = new fabric.Text(format(date, 'PP'), {
            fontSize: 16,
            //originX: 'center',
            //originY: 'bottom',
            top: -selectorHeight,//maxBarHeight,
            angle: -90,
        });
        text.set('isDayLabel', true);
        text.set('moodData', {dirty: true});
        elements.push(text);

        //console.log(k, moodsList);
        const rects = [];

        moodsList.sort((a, b) => a.created - b.created).forEach((m, i) => {
            //console.log(m, m.moodLevel, moodColors[m.moodLevel]);
            const mood = maxMoodLevel - m.moodLevel;

            const rect = new fabric.Rect({
                left: 0,//20 + i * (barWidth + barSpace),
                top: maxBarHeight,
                originX: 'left',
                originY: 'bottom',
                fill: hexToRGBA(MOOD_COLORS[m.moodLevel].color, 1),
                width: barWidth,
                height: (mood / maxMoodLevel) * maxBarHeight,
                hoverCursor: 'pointer',
            });

            const text = new fabric.Text(mood.toString(), {
                fontSize: 16,
                originX: 'center',
                originY: 'bottom',
                left: rect.left + rect.width * 0.5,
                top: maxBarHeight - rect.height,
                //angle: -90,
            });

            let triangle = new fabric.Triangle({
                top: maxBarHeight - selectorHeight,
                left: rect.left + rect.width + 0,
                width: rect.width,
                height: 6,
                fill: rect.fill,
                stroke: 'black',
                strokeWidth: 0,
                //cornerColor: 'blue',
                angle: 180,
                originY: 'bottom',
            });

            rect.set({ 'moodData': { ...m, __selector: triangle, dirty: true } });

            // rect.on('selected', function(options) {
            //     console.log('selected a rect', options);
            //   });

            rect.on('mousedown', onRectSelect);

            rects.push(rect, text, triangle);
            //group.add(rect);

            const g = new fabric.Group([rect, text, triangle], {
                left: 0,
                top: 0,
                originX: "left",
                originY: "bottom",
                selectable: false,
                subTargetCheck: true,
                //height: maxBarHeight + 34,
                //objectCaching: false,
                //angle: -10
            });
            g.height = g.height + selectorHeight + 3;
            g.set('moodData', {dirty: true});
            //console.log(g.height);
            elements.push(g);
        });

        // const group = new fabric.Group([text, ...rects], {
        //     left: prevGroupPos.x ? prevGroupPos.x + 12 : 0,
        //     //top: bottomBarPadding,
        //     originX: "left",
        //     originY: "bottom",
        //     selectable: false,
        //     subTargetCheck: true,
        //     //objectCaching: false,
        //     //angle: -10
        // });

        // groups.push(group);

        // prevGroupPos.x = group.left + group.width;
        // prevGroupPos.w = group.width;
    });

    return elements || groups;
}