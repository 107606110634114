import React from "react";
import { createRoot } from "react-dom/client";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Provider } from 'react-redux';

import store from './data/store';
import { DashboardPage } from "./pages/Dashboard";

const theme = createTheme();

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Provider store={store}>
                <DashboardPage />
            </Provider>
        </ThemeProvider>
    </LocalizationProvider>
);