//int n, double[] dx, int incx, double[] dy, int incy
//ret double
export const svd_ddot = (n, dx, incx, dy, incy) => {
    let dot_product = 0.0;
    let ix0 = 0;
    let iy0 = 0;

    //assert incx == 1 || incx == -1 || incx == 0;
    //assert incy == 1 || incy == -1 || incy == 0;
    if (n <= 0 || incx == 0 || incy == 0) return 0.0;

    let ix = (incx == 1) ? ix0 : n - 1 + ix0;
    let iy = (incy == 1) ? iy0 : n - 1 + iy0;
    for (let i = 0; i < n; i++) {
        dot_product += dy[iy] * dx[ix];
        iy += incy;
        ix += incx;
    }

    return dot_product;
}

//double t, int nSplines
//ret double
export const bSpline = (t, nSplines) => {
    t = t % nSplines;
    if (t < 0)
        t += nSplines;
    if (t > 4)
        return 0;
    if (t > 2)
        t = 4 - t;
    if (t < 1)
        return t * t * t;
    return ((12 - 3 * t) * t - 12) * t + 4;
}

export const transpose = (matrix) => {
    let res = Array(matrix[0].length).fill().map(() => []);
    for (let i = 0; i < res.length; i++) {
        for (let j = 0; j < matrix.length; j++) {
            res[i][j] = matrix[j][i];
        }
    }
    return res;
}