import React, { useEffect } from 'react';

import Grid from '@mui/material/Unstable_Grid2';
import { Box, Container, Stack } from '@mui/material';

import { MoodHistory } from "../component/moodHistory";
import { MainLayout } from "../layout";
import { MoodSummary } from '../component/moodSummary';
import { MoodTagsSummary } from '../component/moodTagsSummary';
import { MoodByWeek } from '../component/moodSummary/moodByWeek';
import { MoodByDay } from '../component/moodSummary/moodByDay';
import { useDispatch } from 'react-redux';
import { fetchResources, getMoodsAction } from '../data/sagas/appSaga';
import { MoodSummaryStatic } from '../component/moodSummary/moodSummaryStatic';

import style from "./dashboard.module.scss";
import { MoodByCustomPeriod } from '../component/moodSummary/moodByCustomPeriod';

const DashboardPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            await fetchResources();
            dispatch(getMoodsAction());
        })();
    }, []);

    return (
        <MainLayout>
            <div className={style.stackWrapper} style={{ flexGrow: 1, padding: 12 }}>
                <Stack direction="row" alignItems="flex-end" justifyContent="flex-start" spacing={2}>
                    <MoodByWeek />
                    <MoodByDay />
                    <MoodByCustomPeriod />
                </Stack>
                <Stack direction="row" alignItems="flex-end" justifyContent="flex-start" spacing={2}>
                    <MoodSummaryStatic />
                    <MoodSummary />
                </Stack>
                <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={2}>
                    <MoodHistory />
                    <MoodTagsSummary />
                </Stack>
            </div>
            {/* <div style={{ flexGrow: 1, padding: 12 }}>
                <Grid container={true} spacing={2} wrap='nowrap'>
                    <Grid container={true} item={true} spacing={2} direction='column' >
                        <Grid item={true}><MoodByWeek /></Grid>
                        <Grid item={true}><MoodSummary /></Grid>
                    </Grid>
                    <Grid container={true} item={true} spacing={2} direction='column'>
                        <Grid item={true}><MoodByDay /></Grid>
                    </Grid>

                </Grid>
            </div>
            <div style={{ flexGrow: 1, padding: 12 }}>
                <Grid container={true} spacing={2} wrap='nowrap'>
                    <Grid container={true} item={true} spacing={2} direction='column' >
                        <Grid item={true}><MoodHistory /></Grid>
                    </Grid>
                    <Grid container={true} item={true} spacing={2} direction='column'>
                        <Grid item={true}><MoodTagsSummary /></Grid>
                    </Grid>
                </Grid>
            </div> */}
        </MainLayout>
    );
}

export { DashboardPage };