import { applyMiddleware, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import appReducer from './appSlice';
import appSaga from './sagas/appSaga';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        app: appReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(sagaMiddleware),
})

sagaMiddleware.run(appSaga);

export default store;