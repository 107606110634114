import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        moods: [],
        resources: {},
        filters: {
            history: {
                tags: [],
            }
        }
    },
    reducers: {
        moodsReceived: (state, action) => {
            state.moods = action.payload;
        },
        historyTagsFilterReceived: (state, action) => {
            state.filters.history.tags = action.payload ? [action.payload] : [];
        }
    },
});

export const { moodsReceived, historyTagsFilterReceived } = appSlice.actions;
export const historyTagsFilterSelector = (state) => state.app.filters.history.tags;

export default appSlice.reducer;