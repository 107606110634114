import { SVD } from 'svd-js';
import { bSpline, svd_ddot, transpose } from "./math";

export const positiveFilter = (x) => {
    let a = 0.02;
    return x < a ? (a * a) / (2 * a - x) : x;
}

//int nPoints, double[] iaX, double[] iaY, int nSplines, double[] oaCoef
//ret Integer, Double
export const fitData = (nPoints, iaX, iaY, nSplines, oaCoef) => {
    let sum = 0;
    for (let point = 0; point < nPoints; point++) {
        sum += iaY[point];
    }
    let avg = sum / nPoints;
    let tmp = [];//len nPoints
    for (let point = 0; point < nPoints; point++) {
        tmp[point] = iaY[point] - avg;
    }

    let rank = fitCenteredData(nPoints, iaX, tmp, nSplines, oaCoef);

    return [rank, avg];
}

//int nPoints, double[] iaX, double[] iaY, int nSplines, double[] oaCoef
//ret int
export const fitCenteredData = (nPoints, iaX, iaY, nSplines, oaCoef) => {
    //DMat basis = new DMat(nPoints, nSplines);
    let basis = [];
    for (let point = 0; point < nPoints; point++) {
        let x = iaX[point];
        for (let spline = 0; spline < nSplines; spline++) {
            if (!basis[point]) basis[point] = [];

            basis[point][spline] = bSpline(x - spline, nSplines);
        }
    }

    //SMat sparseBasis = SvdHelper.svdConvertDtoS(basis);

    //SVDRec svd = svdlib.svdLAS2A(sparseBasis, 0);
    let svd = SVD(basis);

    svd.S = svd.q;
    svd.U = transpose(svd.u);
    svd.V = svd.v;
    svd.d = svd.q.length;

    let minsing = 0.2 * svd.S[0];
    let tmp = new Array(svd.d).fill(0);
    let rank = 0;
    for (let j = 0; j < svd.d; j++) {
        if (svd.S[j] > minsing) {
            tmp[j] = svd_ddot(nPoints, svd.U[j], 1, iaY, 1) / svd.S[j];
            rank++;
        }
    }
    //DMat v = SvdHelper.svdTransposeD(svd.Vt);

    for (let spline = 0; spline < nSplines; spline++) {
        oaCoef[spline] = svd_ddot(svd.d, svd.V[spline], 1, tmp, 1);
    }

    return rank;
}



//double x, int nSplines, double[] iaCoefs, double cnst
//ret double
export const evalResult = (x, nSplines, iaCoefs, cnst) => {
    let res = cnst;
    for (let spline = 0; spline < nSplines; spline++) {
        res += bSpline(x - spline, nSplines) * iaCoefs[spline];
    }
    return res;
}

export const calcGraph = (data, startDate, period, detailLevel, cyclic) => {
    let pointData = [];
    data.forEach((e) => {
        let x = (e.created - startDate) / period;
        if (cyclic) {
            x = x % 1;
            if (x < 0)
                x += 1;
        }
        pointData.push([detailLevel * x, 1 - ((10 - e.moodLevel) - 0.5) / 10.0, e.created, e.moodLevel, new Date(e.created)]);
    });

    pointData.sort((a, b) => a[0] - b[0]);

    let aX = [];//len nPoints
    let aY = [];//len nPoints
    for (let i = 0; i < pointData.length; ++i) {
        let point = pointData[i];
        aX[i] = point[0];
        aY[i] = point[1];
    }

    let nPoints = pointData.length;
    let nSplines = detailLevel;//todo iphone adds 4 (cyclic ? 0 : 4)
    let aCoef = [];//len nSplines
    let tmp = fitData(nPoints, aX, aY, nSplines, aCoef);
    let rank = tmp[0];
    let cnst = tmp[1];

    let aDisp = new Array(nPoints).fill(0);
    for (let point = 0; point < nPoints; point++) {
        aDisp[point] = Math.abs(aY[point] - evalResult(aX[point], nSplines, aCoef, cnst));
    }
    let aDispCoef = new Array(nSplines).fill(0);
    let dispCnst = 0;
    fitCenteredData(nPoints, aX, aDisp, nSplines, aDispCoef);

    const N_SAMPLES = 100;
    let aSamples = new Array(N_SAMPLES).fill(0);
    let aDispSamples = new Array(N_SAMPLES).fill(0);
    let step = detailLevel / (N_SAMPLES - 1.0);

    for (let i = 0, x = 0; i < N_SAMPLES; i++, x += step) {
        aSamples[i] = evalResult(x, nSplines, aCoef, cnst);
        aDispSamples[i] = positiveFilter(evalResult(x, nSplines, aDispCoef, dispCnst));
    }

    return { aSamples, aDispSamples, aX, aY, step, rank };
}