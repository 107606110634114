import React, { useEffect } from "react";
import { Box, Button, Card, IconButton, Stack, Tab, Tabs, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { setDay } from "date-fns";
import FilterListIcon from '@mui/icons-material/FilterList';
import { DatePicker } from "@mui/x-date-pickers";

const DEFAULT_PERIOD_IN_DAYS = '30';
const defaultFilterValues = {
    dateFrom: '',
    dateTo: '',
    periodInDays: DEFAULT_PERIOD_IN_DAYS,
    periodFrom: (() => {
        let startDate = new Date();
        startDate = setDay(startDate, 0);
        startDate.setUTCHours(6, 0, 0, 0);
        return startDate;
    })(),
}

const maxPeriodDay = new Date();

const MoodPeriodFilter = ({ dateRange, onFilterSubmit }) => {
    const [isShowFilter, toggleFilter] = React.useState(false);

    const { control, reset, handleSubmit } = useForm({
        defaultValues: defaultFilterValues,
    });

    useEffect(() => {
        reset({
            ...defaultFilterValues,
            dateFrom: dateRange.min,
            dateTo: dateRange.max,
        });
    }, [dateRange]);

    const toggleFilterClick = () => toggleFilter(!isShowFilter);

    const onFilterReset = () => {
        reset({ ...defaultFilterValues, dateFrom: dateRange.min, dateTo: dateRange.max });
    };

    const onFilterLastYear = () => {
        let dateFrom = new Date(dateRange.max);
        dateFrom.setFullYear(dateFrom.getFullYear() - 1);
        reset({ ...defaultFilterValues, dateFrom: Math.max(dateFrom.getTime(), dateRange.min), dateTo: dateRange.max });
    };

    const onFilterLastMonth = () => {
        let dateFrom = new Date(dateRange.max);
        dateFrom.setMonth(dateFrom.getMonth() - 1);
        reset({ ...defaultFilterValues, dateFrom: Math.max(dateFrom.getTime(), dateRange.min), dateTo: dateRange.max });
    };

    const onFilterLastWeek = () => {
        let dateFrom = new Date(dateRange.max);
        dateFrom.setDate(dateFrom.getDate() - 7);
        reset({ ...defaultFilterValues, dateFrom: Math.max(dateFrom.getTime(), dateRange.min), dateTo: dateRange.max });
    };

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <div></div>
                <IconButton color={isShowFilter ? "primary" : "standart"} aria-label="filter" onClick={toggleFilterClick}>
                    <FilterListIcon />
                </IconButton>
            </Box>
            {isShowFilter &&
                <Box sx={{ margin: '10px' }}>
                    <form onSubmit={handleSubmit(onFilterSubmit)}>
                        <Stack direction='row' spacing={2}>
                            <Controller
                                name="dateFrom"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        label='Date From'
                                        minDate={dateRange.min}
                                        maxDate={dateRange.max}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                )}
                            />
                            <Controller
                                name="dateTo"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        label='Date To'
                                        minDate={dateRange.min}
                                        maxDate={dateRange.max}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack direction='row' spacing={0}>
                            <Button variant="text" onClick={onFilterReset}>All</Button>
                            <Button variant="text" onClick={onFilterLastYear}>Year</Button>
                            <Button variant="text" onClick={onFilterLastMonth}>Month</Button>
                            <Button variant="text" onClick={onFilterLastWeek}>Week</Button>
                        </Stack>
                        <Stack direction='row' spacing={2}>
                            <Controller
                                name="periodFrom"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        label='Period From'
                                        minDate={dateRange.min}
                                        maxDate={maxPeriodDay}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                )}
                            />
                            <Controller
                                name="periodInDays"
                                control={control}
                                //rules={{ pattern: /^\d+$/ }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Period"
                                        type="number"
                                    />
                                )}
                            />
                        </Stack>
                        <br />
                        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                            <Button type="submit" variant="outlined">Apply</Button>
                        </Box>
                    </form>
                </Box>
            }
        </div>
    );
}

export { MoodPeriodFilter };