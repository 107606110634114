import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Box, Card, Tab, Tabs } from "@mui/material";
import { setDay } from "date-fns";

import { MoodSummaryFilter } from "../moodSummaryFilter";
import { calcGraph } from "../../utils/graph";
import { hexToRGBA } from "../../utils/draw";
import { a11yProps } from "../../utils/muiHelper";
import { CHART_DETAIL_LEVEL } from "./../../data";
import { drawBG, drawWeekUI, getDotsPath, getMainTrendPath, getSecondaryTrendPath, Trend } from "./helpers";

const TAB_ID = {
    WEEK: 0,
}

const MoodByWeek = () => {
    const [trends, setTrends] = React.useState(null);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [filters, setFilters] = React.useState([]);//{id, filter}
    const [dateRange, setDateRange] = React.useState({});

    const moods = useSelector((state) => state.app.moods);

    const canvasRef = useRef({});

    useEffect(() => {
        if (!moods.length) return;

        let trendsData = {};

        //week
        let startDate = new Date();
        startDate = setDay(startDate, 0);
        startDate.setUTCHours(0, 0, 0, 0);
        trendsData[TAB_ID.WEEK] = new Trend(startDate.getTime(), 1000 * 60 * 60 * 24 * 7, CHART_DETAIL_LEVEL, true);
        trendsData[TAB_ID.WEEK].drawUI = drawWeekUI;

        setTrends(trendsData);
        setDateRange({ min: moods[0].created, max: moods[moods.length - 1].created });
        console.log('INIT TRENDS', trendsData);
    }, [moods]);

    useEffect(() => {
        if (!trends) return;

        console.log('REDRAW');
        let ldb = moods;
        let trend = trends[tabIndex];

        //apply filters
        if (filters.length > 0) {
            let filteredData = ldb.filter(filters[0].exec());

            if (filteredData.length === 0) {
                console.warn("No records after filter apply");
            } else {
                ldb = filteredData;
                console.log('Filtered: ', ldb.length);

                trends[tabIndex].detailLevel = Math.min(CHART_DETAIL_LEVEL, ldb.length);
            }
        }

        let { startDate, period, detailLevel, cyclic } = trend;
        let { aSamples, aDispSamples, aX, aY, step } = calcGraph(ldb, startDate, period, detailLevel, cyclic);

        let xCoef = canvasRef.current.width / detailLevel;
        let yCoef = canvasRef.current.height;

        let ctx = canvasRef.current.getContext("2d");

        drawBG(canvasRef.current, ctx);

        let backPath = getSecondaryTrendPath(canvasRef.current, aSamples, aDispSamples, xCoef, yCoef, step);
        let trendPath = getMainTrendPath(aSamples, xCoef, yCoef, step);
        let dotsPath = getDotsPath(aX, aY, xCoef, yCoef);

        ctx.lineWidth = '0.3';
        ctx.strokeStyle = 'white';
        ctx.fillStyle = hexToRGBA('#000000', 0.1);

        ctx.fill(backPath);
        ctx.stroke(backPath);

        ctx.lineWidth = '1.2';
        ctx.strokeStyle = 'white';
        ctx.stroke(trendPath);

        ctx.fillStyle = "white";
        ctx.fill(dotsPath);

        ctx.font = "normal 16px sans-serif";
        ctx.fillStyle = hexToRGBA('#FFFFFF', 0.4);

        trend.drawUI(canvasRef.current, ctx);
    }, [canvasRef, tabIndex, trends, filters, moods]);

    const handleChange = (_, newValue) => {
        setTabIndex(newValue);
    };

    const onFilterSubmit = data => {
        let { dateFrom, dateTo } = data;

        setFilters([{
            filter: {
                dateFrom: new Date(dateFrom).getTime(),
                dateTo: new Date(dateTo).getTime(),
            },
            exec: function () { return (d) => d.created >= this.filter.dateFrom && d.created <= this.filter.dateTo },
        }]);
    }

    return (
        <Card sx={{ width: '480px' }}>
            <MoodSummaryFilter
                dateRange={dateRange}
                onFilterSubmit={onFilterSubmit}
            />
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabIndex} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="By Week" {...a11yProps('mood-summary', TAB_ID.WEEK)} />
                </Tabs>
            </Box>
            <div>
                <canvas ref={canvasRef} width={480} height={360} />
            </div>
        </Card>
    );
}

export { MoodByWeek };