import { format, setDay } from "date-fns";
import { MOOD_COLORS } from "../../../data";
import { hexToRGBA } from "../../../utils/draw";

export class Trend {
    startDate;
    period;
    cyclic;
    detailLevel;
    drawUI;

    constructor(startDate, period, detailLevel, cyclic) {
        this.startDate = startDate;
        this.period = period;
        this.detailLevel = detailLevel;
        this.cyclic = cyclic;
    }
}

export function drawBG(canvas, ctx) {
    let bg = ctx.createLinearGradient(0, 0, 0, canvas.height);

    MOOD_COLORS.forEach((c, i) => {
        let offset = i == MOOD_COLORS.length - 1 ? 1 : i / MOOD_COLORS.length;
        bg.addColorStop(offset, hexToRGBA(c.color, 1));
    });

    ctx.fillStyle = bg;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
}

export function getMainTrendPath(aSamples, xCoef, yCoef, step) {
    let trendPath = new Path2D();
    trendPath.moveTo(0, (aSamples[0] * yCoef));

    for (let i = 1, x = step; i < aSamples.length; i++, x += step) {
        let curX = x * xCoef;
        let curY = aSamples[i] * yCoef;
        trendPath.lineTo(curX, curY);
    }

    return trendPath;
}

export function getSecondaryTrendPath(canvas, aSamples, aDispSamples, xCoef, yCoef, step) {
    let backPath = new Path2D();
    backPath.moveTo(0, canvas.height);

    for (let i = 0, x = 0; i < aSamples.length; i++, x += step) {
        let curX = x * xCoef;
        let curY = (aSamples[i] + aDispSamples[i]) * yCoef;
        backPath.lineTo(curX, curY);
    }
    backPath.lineTo(canvas.width, canvas.height);
    //upperPath.closePath();

    //lower
    //let lowerPath = new Path2D();
    backPath.moveTo(0, 0);

    for (let i = 0, x = 0; i < aSamples.length; i++, x += step) {
        let curX = x * xCoef;
        let curY = (aSamples[i] - aDispSamples[i]) * yCoef;
        backPath.lineTo(curX, curY);
    }
    backPath.lineTo(canvas.width, 0);
    backPath.closePath();

    return backPath;
}

export function getDotsPath(aX, aY, xCoef, yCoef) {
    let dotsPath = new Path2D();
    for (let point = 0; point < aX.length; point++) {
        let curX = aX[point] * xCoef;
        let curY = aY[point] * yCoef;
        dotsPath.moveTo(curX, curY);
        dotsPath.arc(curX, curY, 1.5, 0, 2 * Math.PI);
    }

    return dotsPath;
}

export function drawSummaryUI(canvas, ctx) {
    let textBottomPadding = 6;
    let textSidePadding = 6;

    ctx.textAlign = "start";
    ctx.fillText(format(new Date(Number(this.startDate)), 'PP'), textSidePadding, canvas.height - textBottomPadding);
    ctx.textAlign = "end";
    ctx.fillText(format(new Date(Number(this.period + this.startDate - 2)), 'PP'), canvas.width - textSidePadding, canvas.height - textBottomPadding);
}

export function drawWeekUI(canvas, ctx) {
    let count = 7;
    let timer = new Date(this.startDate);

    ctx.lineWidth = '0.3';
    ctx.strokeStyle = '#C7CA9D';
    //ctx.stroke(trendPath);
    ctx.textAlign = "end";

    for (let i = 1; i <= count; ++i) {
        let x = (canvas.width * i) / count;
        if (i != count) {
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, canvas.height);
            ctx.stroke();
        }
        //ctx.stroke();
        x = (x + ((canvas.width * (i - 1)) / count)) / 2;
        let label = format(timer, 'EEE');
        ctx.fillText(format(timer, 'EEE'), x + ctx.measureText(label).width / 2, canvas.height - 5);
        timer = setDay(timer, timer.getDay() + 1);
    }
}

export function drawDayUI(canvas, ctx) {
    let hourStep = 3;
    let count = 24 / hourStep;
    let timer = new Date(this.startDate);
    timer.setHours(timer.getHours() + timer.getTimezoneOffset() / 60);

    ctx.lineWidth = '0.3';
    ctx.strokeStyle = '#C7CA9D';
    ctx.textAlign = "end";

    for (let i = 1; i <= count; ++i) {
        let x = (canvas.width * i) / count;
        if (i != count) {
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, canvas.height);
            ctx.stroke();
        }
        timer.setHours(timer.getHours() + hourStep);
        x = (canvas.width * (i)) / count;

        let label = format(timer, 'p');

        //ctx.save();
        ctx.translate(x - 5, canvas.height - ctx.measureText(label).width - 5);
        ctx.rotate(-Math.PI / 2);
        ctx.fillText(format(timer, 'p'), 0, 0);
        //ctx.restore();
        ctx.resetTransform();
    }
}