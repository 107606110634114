const ImageWithSlice = fabric.util.createClass(fabric.Image, {
    type: 'imageWithSlice',

    imageWidth: 1,
    imageHeight: 1,

    initialize: function (element, options) {
        options || (options = {});

        // options.width = this.spriteWidth;
        // options.height = this.spriteHeight;
        this.imageWidth = options.imageWidth;
        this.imageHeight = options.imageHeight;

        this.callSuper('initialize', element, options);
    },

    _render: function (ctx) {
        // ctx.drawImage(
        //     this.spriteImages[this.spriteIndex],
        //     -this.width / 2,
        //     -this.height / 2
        // );
        const s = this.imageWidth / 3;
        const left = [0, 0, s - 0, this.imageHeight + 2];
        const middle = [s, 0, s - 0, this.imageHeight - 0];
        const right = [s * 2, 0, s - 0, this.imageHeight + 2];

        let h =  this.height - 0;
        let dh = 2;
        //ctx.drawImage(this._element, 0,0, this.width, this.height,0,0, this.width, this.height);
        ctx.drawImage(this._element, ...left, -this.width / 2, -h / 2 - dh, s, h);
        ctx.drawImage(this._element, ...middle, s - this.width / 2, -h / 2 - dh, this.width - s * 2, h);
        ctx.drawImage(this._element, ...right, this.width - s - this.width / 2, -h / 2 - dh, s, h);
    },
});

const addImageWithSliceToFabric = () => {
    fabric.ImageWithSlice = ImageWithSlice;

    fabric.ImageWithSlice.fromURL = function (url, callback, imgOptions) {
        fabric.util.loadImage(url, function (img) {
            callback(new fabric.ImageWithSlice(img, imgOptions));
        });
    };

    fabric.ImageWithSlice.async = true;
}

export { addImageWithSliceToFabric };