import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Card, Chip, IconButton, Typography } from "@mui/material";
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import FilterListIcon from '@mui/icons-material/FilterList';
import { fabric } from 'fabric';

import { db, MOOD_COLORS } from "./../../data";
import { format } from "date-fns";
import { hexToRGBA } from "../../utils/draw";
import { addImageWithSliceToFabric } from "./../../utils/fabricExt/ImageWithSlice";
import { barWidth, getGroups } from "./helpers/graph";
import { moodTagSelectedAction, RESOURCES } from "../../data/sagas/appSaga";
import { historyTagsFilterSelector } from "../../data/appSlice";

import style from "./moodHistory.module.scss";

addImageWithSliceToFabric();

const groupByCreated = (entities) => {
    const result = {};

    entities.forEach(e => {
        const createdDate = new Date(e.created);
        createdDate.setHours(0, 0, 0, 0);

        const key = createdDate.getTime();

        if (result.hasOwnProperty(key)) {
            result[key].push({ ...e });
        } else {
            result[key] = [{ ...e }];
        }
    });

    return result;
}

let __selectorRef = null;
let __fabricCanvas = null;
let __fabricGraphGroup = null;

const MoodHistory = () => {
    const [selectedMood, setSelectedMood] = useState(null);
    const [elementsSplit, setElementsSplit] = useState([]);
    const [pagination, setPagination] = useState({});

    const canvasRef = useRef({});
    const dispatch = useDispatch();

    const moodsEntities = useSelector((state) => state.app.moods);
    const tagsFilter = useSelector(historyTagsFilterSelector);

    useEffect(() => {
        if (!moodsEntities.length) return;

        let moodsList = moodsEntities;
        if (tagsFilter.length) {
            moodsList = moodsEntities.filter(e => e.text.toLowerCase().includes('#' + tagsFilter[0].toLowerCase()));
        }

        const moods = groupByCreated(moodsList);

        let canvas = __fabricCanvas;
        if (!canvas) {
            canvas = new fabric.Canvas(canvasRef.current);
            canvas.backgroundColor = 'aliceblue';
            canvas.selection = false;
            __fabricCanvas = canvas;
        } else {
            canvas.clear();
        }

        const groups = getGroups(
            moods,
            function (evt) {
                console.log('rect mousedown', evt.subTargets, evt.subTargets[0].moodData);
                setSelectedMood((prev) => {
                    // prev?.__target.group.forEachObject(el => {
                    //     if (el.type !== "triangle") return;
                    //     el.set({ top:  prev.__target.get('top') - el.get('height') - 1 });
                    // });

                    //prev?.__selector.set({ top: prev.__target.get('top') - prev.__selector.get('height') - 1 });
                    prev?.__selector.animate('top', prev.__target.get('top') - prev.__selector.get('height') - 1, {
                        onChange: __fabricCanvas.renderAll.bind(__fabricCanvas),
                        duration: 250,
                        //easing: fabric.util.ease.easeOutBounce
                    });

                    return { ...evt.subTargets[0].moodData, __target: evt.subTargets[0] };
                });
            },
            canvas
        );

        const widthLimit = canvasRef.current.width;
        let groupsSplit = [];
        let splitG = [];
        let splitGW = 0;
        for (let i = groups.length - 1; i >= 0; i--) {
            let width = groups[i].isDayLabel ? 20 + 12 : groups[i].width;//for rotated text width->heigth

            if (splitGW + width <= widthLimit) { //splitGW + groups[i].width < widthLimit
                splitG.push(groups[i]);
                splitGW += width;
            } else {
                if (splitG.length) groupsSplit.push(splitG.reverse());
                splitG = [groups[i]];
                splitGW = width;
            }
        }

        if (splitG.length) {
            groupsSplit.push(splitG.reverse());
        }
        //groupsSplit.reverse();
        setElementsSplit(groupsSplit);

        //console.log(groupsWidth, groupsWidth + groupsSplit.reduce((prev, cur) => prev + cur.length * 12, 0) - 12, widthLimit);//40529
        //console.log(groupsSplit);
        let groupsSplitTotalWidth = groupsSplit.reduce((prev, cur) => prev + cur.reduce((prev, cur) => prev + (cur.isDayLabel ? 20 + 12 : cur.width), 0), -12);
        console.log(groupsSplitTotalWidth, groupsSplit.length, Math.ceil(groupsSplitTotalWidth / canvas.width));
        console.log(groupsSplit[0], (groupsSplit[0].reduce((prev, cur) => prev + (cur.isDayLabel ? 20 + 12 : cur.width), 0)));

        //groupsSplit.forEach((g)=>console.log(g.length, g.reduce((prev, cur) => prev + (cur.isDayLabel?20+12:cur.width), 0)));

        let prevG = null;//{left:0, width:0};
        groupsSplit[0].forEach((g) => {
            let margin = g.isDayLabel ? 12 : 0;
            let width = prevG?.isDayLabel ? 20 : prevG?.width;
            g.set({
                left: (prevG ? prevG.left + width : 0) + margin,
                //top: 
            });
            //g.set('top', 50);
            prevG = g;
        });

        // groupsSplit[0][1].set('left', 50);
        // groupsSplit[0][1].set('top', -450);

        //const historyGroup = new fabric.Group(groups, {
        const historyGroup = new fabric.Group([...groupsSplit[0]], {
            top: canvas.height - 26,
            left: 0,
            originX: "left",
            originY: "bottom",
            selectable: false,
            subTargetCheck: true,
            hoverCursor: 'auto',
            objectCaching: false,
        });
        // historyGroup.addWithUpdate();
        // historyGroup.setCoords();
        const bottomLineGroup = getBottomLineGroup(canvas, historyGroup);

        canvas.add(historyGroup);
        canvas.add(bottomLineGroup);

        bottomLineGroup.set({ left: 0, top: canvas.height });

        //historyGroup.addWithUpdate(ggg);
        //canvas.renderAll();
        //historyGroup.addWithUpdate(groupsSplit[0][1]);
        // historyGroup.forEachObject(el => {
        //     el.set('left', 0)
        //     el.set('top', 100)
        // });
        // canvas.renderAll();
        __fabricGraphGroup = historyGroup;
        console.log('GROUP WIDTH:', historyGroup.width);

        // historyGroup.on('mouse:down', function(options) {
        //     console.log('selected a rectangle', options);
        //   });

        if (historyGroup.width > canvas.width)
            historyGroup.set({ left: -historyGroup.width + canvas.width - barWidth });

        setPagination({
            total: Math.ceil(groupsSplitTotalWidth / canvas.width),
            page: Math.ceil(groupsSplitTotalWidth / canvas.width),
            step: canvas.width,
            dataStep: 3,
            data: { from: 0, to: 0 }
        });

        let lastMood = groupsSplit[0].at(-1).getObjects().filter(e => e.type == 'rect').at(-1);
        setSelectedMood({ ...lastMood.moodData, __target: lastMood });

    }, [moodsEntities, tagsFilter, canvasRef]);

    useEffect(() => {
        if (!selectedMood) return;

        let target = selectedMood.__target;
        //selectedMood.__selector.set({ top: target.get('top') - 0 });

        selectedMood.__selector.animate('top', target.get('top') - 0, {
            onChange: __fabricCanvas.renderAll.bind(__fabricCanvas),
            duration: 250,
            //easing: fabric.util.ease.easeOutBounce
        });

        //__selectorRef?.set({ left: target.get('left') + target.group.get('left') + target.group.get('width') / 2 });
        __selectorRef?.animate('left', target.get('left') + target.group.get('left') + target.group.get('width') / 2, {
            onChange: __fabricCanvas.renderAll.bind(__fabricCanvas),
            duration: 250,
            //easing: fabric.util.ease.easeOutBounce
        });

        console.log('selected mood', selectedMood?.__target);
    }, [selectedMood]);

    useEffect(() => {
        console.log('tag filters', tagsFilter);
    }, [tagsFilter]);

    const getBottomLineGroup = (canvas, historyGroup) => {
        //...evt.subTargets[0].moodData, __target: evt.subTargets[0]
        const bar = new fabric.ImageWithSlice(RESOURCES.bg.image, {
            top: 0,//historyGroup.get('top') - 4,//0 + historyGroup.height/2 + 22,
            left: 0,//historyGroup.get('left') + 8,//0 - historyGroup.width/2,
            width: Math.max(historyGroup.width, canvas.width),//groups.reduce((r, g)=>r+=g.width, 0),
            height: 16,
            imageWidth: 32,
            imageHeight: 22,
            //backgroundColor: 'red',
            originX: "left",
            originY: "bottom",
            selectable: false,
        });

        //draw underbar dots
        const dots = [];
        historyGroup.forEachObject((el) => {
            if (el.type !== 'group') return;
            //console.log(el);

            //7x7
            const dot = new fabric.Image(RESOURCES.dot.image, {
                top: bar.get('top') - 12,//el.get('height'),
                left: el.get('left') + el.width / 2 - 3 + historyGroup.width / 2,
                originX: "left",
                originY: "top",
            });

            dots.push(dot);
        });
        console.log(RESOURCES.dot.image);
        console.log(RESOURCES);

        // g.on('mouse:down', function(options) {
        //     console.log('selected a group', options);
        //   });

        const selector = new fabric.Image(RESOURCES.selector.image, {
            top: bar.get('top') + 5,//0 + historyGroup.height/2 + 22,
            left: bar.get('left') + bar.width - 32,// + bar.get('width') - 32,,
            originX: 'left',
            //backgroundColor: 'red',
            originY: "bottom",
            selectable: false,
        });
        // img.scaleToHeight(300);
        // img.scaleToWidth(300);
        selector.scale(0.7);

        const g = new fabric.Group([bar, ...dots, selector], {
            left: 0,
            top: 0,
            originX: "left",
            originY: "bottom",
            selectable: false,
            subTargetCheck: false,
            //objectCaching: false,
            //angle: -10
        });

        return g;
    }

    const scrollBack = () => {
        if (pagination.page <= 1) return;

        let page = pagination.page - 1;
        let bottomLine = __fabricCanvas.item(1);
        let dataFrom = pagination.data.from;
        let dataTo = pagination.data.to;

        if (pagination.total - page > dataFrom && dataFrom < elementsSplit.length - 1) {
            dataFrom = pagination.data.from + 1;

            if (dataFrom - dataTo >= pagination.dataStep) {
                __fabricGraphGroup.remove(...elementsSplit[dataTo]);
                //__fabricGraphGroup.addWithUpdate();
                //__fabricGraphGroup.setCoords();
                ++dataTo;
            }

            let prevEl = null;
            let elWidth = elementsSplit[dataFrom].reduce((prev, cur) => prev + (cur.isDayLabel ? 20 + 12 : cur.width), 0);
            let startPos = -__fabricGraphGroup.width / 2 - elWidth;

            elementsSplit[dataFrom].forEach((el) => {
                let margin = el.isDayLabel ? 12 : 0;
                let width = prevEl?.isDayLabel ? 20 : prevEl?.get('width');
                el.set('left', (prevEl ? prevEl.left + width : startPos) + margin);
                if (el.moodData.dirty) {
                    el.set('top', el.top + __fabricGraphGroup.height / 2);// 148.5
                    el.moodData.dirty = false;
                }
                prevEl = el;
            });

            __fabricGraphGroup.add(...elementsSplit[dataFrom]);
            __fabricGraphGroup.addWithUpdate();
            __fabricGraphGroup.setCoords();

            __fabricCanvas.remove(bottomLine);
            bottomLine = getBottomLineGroup(__fabricCanvas, __fabricGraphGroup);
            __fabricCanvas.add(bottomLine);
            bottomLine.set({ left: __fabricGraphGroup.left, top: __fabricCanvas.height });
        }

        __fabricGraphGroup.animate('left', -__fabricGraphGroup.width + pagination.step * (pagination.total - page - dataTo + 1), {
            onChange: __fabricCanvas.renderAll.bind(__fabricCanvas),
            duration: 250,
            //easing: fabric.util.ease.easeOutBounce
        });
        bottomLine.animate('left', -__fabricGraphGroup.width + pagination.step * (pagination.total - page - dataTo + 1), {
            onChange: __fabricCanvas.renderAll.bind(__fabricCanvas),
            duration: 250,
            //easing: fabric.util.ease.easeOutBounce
        });

        setPagination({
            ...pagination,
            page,
            data: {
                ...pagination.data,
                from: dataFrom,
                to: dataTo,
            }
        });

        console.log('PAGE', pagination.total, page, dataFrom, dataTo, __fabricGraphGroup.width);
    }

    const scrollForward = () => {
        if (pagination.page >= pagination.total) return;

        let bottomLine = __fabricCanvas.item(1);
        let page = pagination.page + 1;
        let dataFrom = pagination.data.from;
        let dataTo = pagination.data.to;
        if (pagination.total - page < dataTo && dataTo > 0) {
            __fabricGraphGroup.remove(...elementsSplit[dataFrom]);
            --dataFrom;
            --dataTo;

            let prevEl = null;
            let startPos = __fabricGraphGroup.width / 2;

            elementsSplit[dataTo].forEach((el) => {
                let margin = el.isDayLabel ? 12 : 0;
                let width = prevEl?.isDayLabel ? 20 : prevEl?.get('width');
                el.set('left', (prevEl ? prevEl.left + width : startPos) + margin);
                //el.set('top', el.top + __fabricGraphGroup.height / 2);// 148.5
                prevEl = el;
            });

            __fabricGraphGroup.add(...elementsSplit[dataTo]);
            __fabricGraphGroup.addWithUpdate();
            __fabricGraphGroup.setCoords();

            __fabricCanvas.remove(bottomLine);
            bottomLine = getBottomLineGroup(__fabricCanvas, __fabricGraphGroup);
            __fabricCanvas.add(bottomLine);
            bottomLine.set({ left: __fabricGraphGroup.left, top: __fabricCanvas.height });
        }

        __fabricGraphGroup.animate('left', -__fabricGraphGroup.width + pagination.step * (pagination.total - page - dataTo + 1), {
            onChange: __fabricCanvas.renderAll.bind(__fabricCanvas),
            duration: 250,
            //easing: fabric.util.ease.easeOutBounce
        });

        bottomLine.animate('left', -__fabricGraphGroup.width + pagination.step * (pagination.total - page - dataTo + 1), {
            onChange: __fabricCanvas.renderAll.bind(__fabricCanvas),
            duration: 250,
            //easing: fabric.util.ease.easeOutBounce
        });

        setPagination({
            ...pagination,
            page,
            data: {
                ...pagination.data,
                from: dataFrom,
                to: dataTo,
            }
        });
        console.log('PAGE', pagination.total, page, dataFrom, dataTo);
    }

    const resetTagFilter = () => dispatch(moodTagSelectedAction());

    return (
        <Card style={{ width: 'min-content' }}>
            {tagsFilter.length > 0 && (
                <div>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{padding: 4}}>
                            {tagsFilter.map(tag => (
                                <Chip key={tag} label={tag} variant="outlined" onDelete={resetTagFilter}/>
                            ))}
                        </div>
                        <IconButton color="primary" aria-label="filter">
                            <FilterListIcon />
                        </IconButton>
                    </Box>
                </div>
            )}
            <div style={{ height: 100, overflow: 'auto', margin: '4px 8px' }}>
                {selectedMood &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography component="span">
                                {format(selectedMood.edited || selectedMood.created, "Pp")}
                            </Typography>
                            {selectedMood.edited &&
                                <Typography sx={{ color: '#AA3000' }} component="span">
                                    edited
                                </Typography>
                            }
                        </div>
                        <Typography
                            component="span"
                            dangerouslySetInnerHTML={{ __html: selectedMood.text.replace(/#([\w\-']+)/g, `<span class="${style.tag}">$1</span>`) }}
                        >
                        </Typography>
                    </>
                }
            </div>
            <div className={style['graph-wrapper']}>
                <canvas ref={canvasRef} width={480} height={360} />
                <div className={style['graph-controlls']}>
                    <IconButton color="standart" aria-label="back" onClick={scrollBack}>
                        <ArrowBackIosNew />
                    </IconButton>
                    <IconButton color="standart" aria-label="forward" onClick={scrollForward}>
                        <ArrowForwardIos />
                    </IconButton>
                </div>
            </div>
        </Card>
    );
}

export { MoodHistory };