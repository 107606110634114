import * as React from 'react';

import Grid from '@mui/material/Unstable_Grid2';
import { AppBar, Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';

const drawerWidth = 240;

const MainLayout = ({ children }) => {
    //const matchesMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    //   const getDisplayMenuByScreen = () =>
    //     matchesMobileScreen ? <MainMenuMobile /> : <MainMenuDesktop />;

    return (
        // <ThemeProvider theme={ }>
        <Box>
            {/* {getDisplayMenuByScreen()} */}
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        IMood Journal
                    </Typography>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <div style={{ display: 'flex', flex: 1 }}>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                >
                    <Toolbar />
                    <List>
                        {['User 1', 'User 2', 'User 3', 'User 4'].map((text, index) => (
                            <ListItem key={text} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
                <div style={{ flex: 1 }}>
                    {children}
                </div>
            </div>


        </Box>
        //</ThemeProvider>
    );
};

export { MainLayout };